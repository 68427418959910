import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from "vue";
// import { sellRoute } from './sell';
import { auctionRouter } from './auction';
// 根路由404配置
const rootRoutePage404 = [
	{
		path: '/404',
		component: () => import('@/views/pageError.vue'),
	},
	{
		path: '/:pathMatch(.*)',
		redirect: '/404',
	},
];
const routes = [
	{
		path: '/',
		component: () => import('@/views/homepage/newHomePage.vue'),
		name: 'newHomePage',
		children: [
			{
				path: '/v2/worksComponent',
				component: () =>
					import('@/components/worksComponent/worksComponent.vue'),
				name: 'worksComponent',
			},
			{
				path: '/v2/home',
				component: () => import('@/views/landingPage/landingPage.vue'),
				name: 'landingPage',
				meta: {
					linkTags: [{ rel: 'canonical', href: 'https://falcons.com/v2/home' }],
				},
			},
			{
				path: '/v2/handbags/:productId', // handbag productDetail
				component: () => import('@/views/bagsDetailPage/bagsDetail.vue'),
				name: 'handbags',
				meta: {
					linkTags: [
						{ rel: 'canonical', href: 'https://falcons.com/v2/handbags' },
					],
				},
			},
			{
				path: '/v2/category/:productId?', //CATEGORY页
				component: () => import('@/views/instructionBook/instrBook.vue'),
				name: 'category',
				meta: {
					linkTags: [
						{ rel: 'canonical', href: 'https://falcons.com/v2/category' },
					],
				},
			},
			{
				path: '/v2/timepieces/:productId', //手表详情页
				component: () => import('@/views/DetailPagesList/DetailPagesIndex.vue'),
				name: '/timepieces',
				meta: {
					linkTags: [
						{ rel: 'canonical', href: 'https://falcons.com/v2/timepieces' },
					],
				},
			},
			{
				path: '/v2/readytoWear/:productId', //高定详情页
				component: () => import('@/views/readytoWear/readytoWear.vue'),
				name: 'readytoWear',
				meta: {
					linkTags: [
						{ rel: 'canonical', href: 'https://falcons.com/v2/readytoWear' },
					],
				},
			},

                {
                    path: '/v2/card/:productId',//card details page
                    component: () => import('@/views/card/card.vue'),
                    name: 'card',
                    meta: {
                        linkTags: [
                            { rel: 'canonical', href: 'https://falcons.com/v2/card' }
                          ]
                    }
                },
                {
                    path: '/v2/cardBox',//cardBox details page
                    component: () => import('@/views/cardBox/cardBox.vue'),
                    name: 'cardBox',
                    meta: {
                        linkTags: [
                            { rel: 'canonical', href: 'https://falcons.com/v2/cardBox' }
                          ]
                    }
                },
                {
                    path: '/v2/otherDetails/:productId',// Other product details page
                    component: () => import('@/views/otherProductDetails/otherProductDetails.vue'),
                    name: 'otherProductDetails',
                    meta: {
                        linkTags: [
                            { rel: 'canonical', href: 'https://falcons.com/v2/otherDetails' }
                          ]
                    }
                },
                {
                    path: '/v2/cars/:id',
                    component: () => import('@/views/car/CarDetail.vue'),
                    name: 'carDetail',
                    meta: {
                        linkTags: [
                            { rel: 'canonical', href: 'https://falcons.com/v2/cars' }
                          ]
                    }
                },    
                ...auctionRouter,
                // {
                //     path: '/v2/collectiblesPage',///Cell4,5页面
                //     component: () =>
                //       import('@/views/CollectiblesPage/CollectiblesPageList.vue'),
                //     name: 'CollectiblesPage',
                // },
                {
                    path: '/v2/sconceptlist',//Concept页
                    component: () => import('@/views/TheSConcept/Category.vue'),
                    name: 'theSConceptCategory',
                    meta: {
                        linkTags: [
                            { rel: 'canonical', href: 'https://falcons.com/v2/sconceptlist' }
                          ]
                    }
                },
                // ...sellRoute,
                {
                    path: '/v2/auction',//auction
                     component: () => import('@/views/auction/auction.vue'),
                    name: 'auction'
                },
                {
                    path: '/v2/livestreaming',//auction
                     component: () => import('@/views/auction/auctionLive.vue'),
                    name: 'livestreaming'
                },
                {
                    path: '/v2/verification',//verification
                     component: () => import('@/views/verificationPage/newVerification.vue'),
                    name: 'verification'
                },
                {
                    path: '/v2/sconcept',
                    component: () => import('@/views/sConcept/sconcept.vue'),
                    name: 'sconcept',
                    meta: {
                        linkTags: [
                            { rel: 'canonical', href: 'https://falcons.com/v2/sconcept' }
                          ]
                    }
                 },
                 {
                    path: '/v2/auction/payment/:auctionId',
                    component: () => import('@/views/auctionPage/payment.vue'),
                    name: 'payment'
                 },   
                 {
                    path: '/v2/sellHomePage',
                    component: () => import('@/views/sellReview/sellHomePage.vue'),
                    name: 'sellHomePage',
                },
                {
                    path: '/v2/sellReview',
                    component: () => import('@/views/sellReview/index.vue'),
                    name: 'sellReview',
                },  
                {
                    path: '/v2/pickSellItem',// handbag productDetail
                    component: () => import('@/views/sellReview/reviewIndex.vue'),
                    name: 'pickSellItem',
                },
                {
                    path: '/v2/auciton/information',
                    component: () => import('@/views/tandcPage/auctionIndexPage.vue'),
                    name: 'aucitonInformation'
                },
        ]
    },
    {
        path: '/homePage',
        component: () => import('@/views/homepage/homePage.vue'),
        name: 'Home',
        
        children: [
            {
                path: '/home',
                component: () => import('@/views/homepage/mainPage.vue'),
                name: 'Home',
            }, {
                path: '/cart',
                component: () => import('@/views/cartPage/cartPage.vue'),
                name: 'Cart'
            }, {
                path: '/collections',
                component: () => import('@/views/collectionsPage/collectionsList.vue'),
                name: 'Collections'
            }, {
                path: '/notification/:id?',
                component: () => import('@/views/notificationPage/notificationPage.vue'),
                name: 'Notification'
            }, {
                path: '/productList',
                component: () => import('@/views/productpage/productList.vue'),
                name: 'ProductList'
            }, {
              path: '/productList',
              component: () => import('@/views/productpage/productList.vue'),
              name: 'ProductList'
            }, {
                path: '/productDetail/:id',
                component: () => import('@/views/productpage/productDetail.vue'),
                name: 'ProductDetail'
            }, {
              path: '/sConceptDetail/:id',
              component: () => import('@/views/productpage/productDetailSConcept.vue'),
              name: 'productDetailSConcept',
              meta: {
                linkTags: [
                    { rel: 'canonical', href: 'https://falcons.com/v2/sConceptDetail' }
                  ]
            }
            },{
                path: '/profile',
                component: () => import('@/views/homepage/userPage.vue'),
                name: 'Profile'
            }, {
                path: '/livesteamsDetail',
                component: () => import('@/views/livesteamspage/livesteamsDetail.vue'),
                name: 'LivesteamsDetail'
            }, {
                path: '/sellItem',
                component: () => import('@/views/sellItemPage/sellItemPage.vue'),
                name: 'SellItem'
            }, {
                path: '/accountMain/:tab',
                component: () => import('@/views/accountPage/accountMainPage.vue'),
                name: 'accountMain',
                meta: { showMenu: false }
            }, {
                path: '/sellItemDetail',
                component: () => import('@/views/sellItemPage/sellItemDetail.vue'),
                name: 'sellItemDetail'
            }, {
                path: '/auctionList',
                component: () => import('@/views/auctionPage/auctionList.vue'),
                name: 'auctionList',
            }, {
                path: '/auctionDetail/:id',
                component: () => import('@/views/auctionPage/auctionDetail.vue'),
                name: 'auctionDetail'
            }, {
                path: '/lotsDetail/:id',
                component: () => import('@/views/auctionPage/lotsDetail.vue'),
                name: 'lotsDetail'
            }, {
                path: '/auctionRoom/:id',
                component: () => import('@/views/auctionPage/auctionRoom.vue'),
                name: 'auctionRoom'
            }, {
                path: '/sellProcess',
                component: () => import('@/views/sellItemPage/sellProcessPage.vue'),
                name: 'SellProcess'
            }, {
                path: '/verification',
                component: () => import('@/views/verificationPage/verificationPage.vue'),
                name: 'Verification'
            }, {
                path: '/checkoutProcess',
                component: () => import('@/views/checkoutPage/checkoutPage.vue'),
                name: 'checkoutProcess'
            },  {
                path: '/checkout',
                component: () => import('@/views/checkoutPage/checkoutResultPage.vue'),
                name: 'checkout'
            }, {
                path: '/sellItemReview',
                component: () => import('@/views/sellItemPage/sellItemReview.vue'),
                name: 'sellItemReview'
            }, {
                path: '/sellItemWithdraw',
                component: () => import('@/views/sellItemPage/sellItemWithdraw.vue'),
                name: 'sellItemWithdraw'
            }, {
                path: '/contactUs',
                component: () => import('@/views/sellItemPage/contactUs.vue'),
                name: 'contactUs',
                meta: {
                    linkTags: [
                        { rel: 'canonical', href: 'https://falcons.com/' }
                      ]
                }
            }, {
                path: '/trackParcel/:id',
                component: () => import('@/views/sellItemPage/parcelTrack.vue'),
                name: 'trackParcel'
            },
            // {
            //     path: '/information',
            //     component: () => import('@/components/homepageComponent/infomationComponent.vue'),
            //     name: 'information'
            // },
            {
                path: '/information',
                component: () => import('@/views/tandcPage/indexPage.vue'),
                name: 'information'
            },
            {
              path: '/concessions',
              component: () => import('@/views/concessions/indexPage.vue'),
              name: 'concessions'
          },
            {
                path: '/aboutUs',
                component: () => import('@/views/homepage/aboutUsPage.vue'),
                name: 'aboutUs',
                meta: {
                    linkTags: [
                        { rel: 'canonical', href: 'https://falcons.com/"' }
                      ]
                }
            }, {
                path: '/FAQ',
                component: () => import('@/views/homepage/FAQPage.vue'),
                name: 'FAQ'
            },{
              path: '/blog',
              component: () => import('@/views/homepage/BlogPage.vue'),
              name: 'blog'
            }, {
              path: '/missionAndVision',
              component: () => import('@/views/homepage/MissionAndVision.vue'),
              name: 'missionAndVision'
            }, {
              path: '/pressAndNews',
              component: () => import('@/views/homepage/PressAndNews.vue'),
              name: 'pressAndNews'
            }, {
                path: '/privacy',
                component: () => import('@/views/privacyPolicyPage/indexPage.vue'),
                name: 'privacy'
            },{
                path: '/Authenticity',
                component: () => import('@/views/homepage/AuthenticityPage.vue'),
                name: 'Authenticity',
                meta: {
                    linkTags: [
                        { rel: 'canonical', href: 'https://falcons.com/"' }
                      ]
                }
            },{
              path: '/sportsCard',
              component: () => import('@/views/sportsCard/indexPage.vue'),
              name: 'SportsCard'
           },{
            path: '/collectorHistory',
            component: () => import('@/views/sportsCard/CollectorHistory.vue'),
            name: 'CollectorHistory'
           },{ 
            path: '/breakOnline',
            component: () => import('@/views/sportsCard/breakOnline.vue'),
            name: 'BreakOnline'
          },
          {
            path: '/sConcept',
            component: () => import('@/views/sConcept/indexPage.vue'),
            name: 'sConcept'
         },
          { 
            path: '/blogDetail',
            component: () => import('@/views/homepage/blogDetail.vue'),
            name: 'BlogDetail'
        },{ 
            path: '/blogBirkin',
            component: () => import('@/views/homepage/blogBirkin.vue'),
            name: 'BlogBirkin'
            }, {
                path: '/blogRolex',
                component: () => import('@/views/homepage/blogRolex.vue'),
                name: 'BlogRolex'
            },
            {
                path: '/blogRise',
                component: () => import('@/views/homepage/blogRise.vue'),
                name: 'BlogRise'
            }
        ],
    }, {
        path: '/login',
        component: () => import('@/views/loginPage/loginPage.vue'),
        name: 'Login'
    }, {
        path: '/signup',
        component: () => import('@/views/loginPage/signUp.vue'),
        name: 'SignUp'
    }, {
        path: '/setPassword',
        component: () => import('@/views/loginPage/setPassword.vue'),
        name: 'SetPassword',
    }, {
        // path:'/oauthLogin/:from',
        path: '/oauthLogin/google',
        component: () => import('@/views/loginPage/oauthLogin.vue'),
        name: 'OauthLogin'
    },
    {
        path: '/demo',
        component: () => import('@/views/demoPage.vue'),
        name: 'demo'
    },
    {
        path: '/faceBookFrom',
        component: () => import('@/views/faceBookFrom.vue'),
        name: 'faceBookFrom'
    },
    { 
      path: '/paypalItem',
      component: () => import('@/views/paypalItem/paypalItem.vue'),
      name: 'PaypalItem'
    },
    { 
      path: '/cybersourceCheckout',
      component: () => import('@/views/paypalItem/CybersourceCheckoutMobile.vue'),
      name: 'cybersourceCheckout'
    },
    
    ...rootRoutePage404
]
const router = createRouter({
	history: createWebHistory(),
	routes,
});
import plugins from '@/plugins/plugins';
router.beforeEach((to, from, next) => {
	nextTick(() => {
		const container = document.querySelector('#app');
		if (container) {
			container.scrollTop = 0;
		}
	});
	const routerParams = [
		'/oauthLogin/google',
		'/setPassword',
		'/contactUs',
		'/checkout',
		'/privacy',
		'/information',
		'/accountMain/1',
	];
	if (!routerParams.includes(from.path)) {
		plugins.setSession('fromPath', from.href);
	} else {
		plugins.setSession('fromPath', '');
	}
	if (to.path === '/') {
		next('/v2/home');
	} else {
		next();
	}
});
export default router;
